<template>
  <el-dialog
    v-if="prescription"
    fullscreen
    :modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
  >
    <div class="relative">
      <div class="">
        <div class="text-center text-2xl font-semibold mb-3">
          Chi tiết Đơn Thuốc
        </div>
        <div class="my-3 text-base">
          <div class="row">
            <div class="col-md-5">
              <div class="font-bold">Tên đơn thuốc</div>
              <div>{{ prescription.name }}</div>
            </div>
            <div class="col-md-4">
              <div class="font-bold">Ngày kê đơn</div>
              <div>{{ formatDate(prescription.date) }}</div>
            </div>
            <div class="col-md-3">
              <div class="font-bold">Bác sĩ kê đơn</div>
              <div>{{ prescription.prescribed_doctor }}</div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-5">
              <div class="font-bold">Cơ sở Y Tế</div>
              <div>{{ prescription.prescribed_clinic }}</div>
            </div>
            <div class="col-md-4">
              <div class="font-bold">Mô tả/Ghi chú</div>
              <div>{{ prescription.description }}</div>
            </div>
            <div class="col-md-3">
              <div class="font-bold">Ngày tạo</div>
              <div>{{ formatDate(prescription.created_at) }}</div>
            </div>
          </div>
        </div>

        <el-table
          class="text-black font-medium text-base w-full"
          :data="prescription.person_medicines"
          border
          :default-sort="{ prop: 'start_date', order: 'descending' }"
        >
          <el-table-column class-name="text-black text-base" label="Tên thuốc">
            <template slot-scope="scope">
              <span class="text-blue-800 ml-2">{{ scope.row.name }}</span>
              <span class="text-blue-800 ml-2">{{
                scope.row.medicine && scope.row.medicine.name
              }}</span>
              <div class="text-sm truncate">
                <span class="ml-3"
                  >Tác dụng phụ: {{ scope.row.side_effect }}</span
                >
              </div>
              <div class="text-sm truncate">
                <span class="ml-3">Hướng dẫn: {{ scope.row.doctor_note }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column class-name="text-black text-base" label="ĐVT">
            <template slot-scope="scope">
              <span class="text-blue-800">{{
                scope.row.medicine && scope.row.medicine.amount_unit
              }}</span>
              <span class="text-blue-800">{{ scope.row.amount_unit }}</span>
            </template>
          </el-table-column>
          <el-table-column class-name="text-black text-base" label="SL">
            <template slot-scope="scope">
              <span class="text-blue-800">{{
                formatNumber(scope.row.amount)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column class-name="text-black text-base" label="Lần / ngày">
            <template slot-scope="scope">
              <span class="text-blue-800"
                >{{ scope.row.freq_per_day }} lần</span
              >
            </template>
          </el-table-column>
          <el-table-column class-name="text-black text-base" label="Lượng/lần">
            <template slot-scope="scope">
              <span class="text-blue-800"
                >{{ formatNumber(scope.row.amount_per_one) }}
                {{ scope.row.medicine && scope.row.medicine.amount_unit }}</span
              >
            </template>
          </el-table-column>
          <el-table-column class-name="text-black text-base" label="Lịch trình">
            <template slot-scope="scope">
              <span class="text-blue-800"
                >{{
                  scope.row.gap_day &&
                  (scope.row.gap_day > 1
                    ? "Cách " + (Number(scope.row.gap_day) - 1)
                    : "Hàng")
                }}
                ngày</span
              >
            </template>
          </el-table-column>
          <!-- <el-table-column
          class-name="text-black text-base"
          label="Ngày bắt đầu"
          sortable
        >
          <template slot-scope="scope">
            <span class="text-blue-800">{{scope.row.start_date ? formatDate(scope.row.start_date) : ''}}</span>
          </template>
        </el-table-column> -->
          <!-- <el-table-column
          class-name="text-black text-base"
          label="Theo dõi"
        >
          <template slot-scope="scope">
            <div><input
                type="checkbox"
                v-model="scope.row.is_monitor"
                @click="changeDrugTracking(scope.row.id,scope.row.is_monitor, $event)"
                style="cursor: pointer;"
              ></div>
          </template>
        </el-table-column> -->
        </el-table>

        <div class="flex justify-end mt-3">
          <div>
          <button type="button"
            class="btn btn-light mr-1"
            @click="handleClose"
            :disabled="loading"
            >Đóng</button
          >
        </div>
        <div>
          <button type="button"
            class="btn bg-pri text-white mr-1"
            
            @click="signDocument"
            :disabled="loading || !!prescription.sign"
          >
            <div>{{ !!prescription.sign ? "Đã" : "" }} Ký</div>
          </button>
        </div>
          <el-tooltip
            content="Phiếu đã ký, không thể sửa"
            placement="top"
            :disabled="!prescription.sign"
          >
          <div>
            <button type="button"
              class="btn bg-pri text-white mr-1"
              
              @click="editPrescription(prescription.id)"
              :disabled="loading || !!prescription.sign"
            >
              <div>Sửa đổi</div>
            </button>
          </div>
          </el-tooltip>
        <div>
          <button type="button"
            class="btn bg-pri text-white mr-1"
            
            @click="exportPdf"
            :disabled="loading"
          >
            <div>Xem / In phiếu</div>
          </button>
          </div>
        </div>
      </div>
      <ModalAddPrescription
        ref="ModalAddPrescription"
        @refresh="getPrescriptionByID"
      />

      <div class="d-none">
        <div ref="TemplatePrescription">
          <TemplatePrescription :person="person" :prescription="prescription" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import html2pdf from 'html2pdf.js'
import ModalAddPrescription from './ModalAdd'
import TemplatePrescription from './Template.vue'
import appUtils from '../../utils/appUtils'
export default {
  name: 'ModalViewPrescription',
  props: ['person'],
  components: { ModalAddPrescription, TemplatePrescription },
  data () {
    return {
      dialogVisible: false,
      prescription: [],
      loading: false,
      prId: 0
    }
  },
  watch: {
    dialogVisible (stt) {
      if (!stt) {
        let query = Object.assign({}, this.$route.query)
        if (query.id) {
          delete query.id
          this.$router.push({ query }).catch(() => {})
        }
      } else {
        let query = Object.assign({}, this.$route.query)
        if (this.prId) {
          query.id = this.prId
          this.$router.push({ query }).catch(() => {})
        }
      }
    }
  },
  methods: {
    formatNumber (value) {
      return +value
    },
    handleClose () {
      this.dialogVisible = false
      this.cancel()
    },
    openDialog (id) {
      this.prId = id
      this.dialogVisible = true
      this.$nextTick(() => {
        if (id) {
          this.getPrescriptionByID(id)
        }
      })
    },
    cancel () {
      this.prescription = []
    },
    formatDate (date) {
      return date && moment(date).format('DD/MM/YYYY')
    },
    async changeDrugTracking (id, status, e) {
      try {
        if (id) {
          const r = confirm('Bạn có chắc chắn thực hiện thao tác này?')
          if (r) {
            if (appUtils.isDoctor()) {
              await this.$rf
                .getRequest('DoctorRequest')
                .postPersonMedicinesMonitor(id)
                .then((res) => {})
            } else if (appUtils.isAdmin()) {
              await this.$rf
                .getRequest('AdminRequest')
                .postPersonMedicinesMonitor(id)
                .then((res) => {})
            }
          } else {
            e.target.checked = status
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    editPrescription () {
      this.$refs.ModalAddPrescription.openDialog(this.prescription)
    },
    isImage (file_name) {
      return file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
    },
    formatDateDMY (date) {
      return moment(date).format('HH:mm DD/MM/YYYY')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    async getPrescriptionByID (id) {
      try {
        if (id) {
          await this.$rf
            .getRequest('DoctorRequest')
            .getPrescriptionByID(id)
            .then((res) => {
              this.prescription = _.cloneDeep(res.data)
            })
        }
      } catch (e) {
        console.log(e)
      }
    },
    exportPdf () {
      this.loading = true
      setTimeout(async () => {
        let options = {
          margin: [7, 11, 7, 7],
          filename: `Don_Thuoc_${this.prescription?.name}_BN_${this.person?.name}.pdf`,
          html2canvas: {
            dpi: 1000,
            scale: 6,
            image: { type: 'png', quality: 1 },
            letterRendering: true,
            allowTaint: true,
            logging: true,
            useCORS: true
          },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }
        try {
          await html2pdf()
            .from(this.$refs.TemplatePrescription)
            .set(options)
            .save()
        } catch (error) {
          console.log(error)
        }
        this.loading = false
      }, 50)
    },
    async signDocument () {
      if (!this.prescription) return
      if (!confirm(`Phiếu đã ký không thể sửa. Bạn có chắc chắn muốn ký?`)) { return }
      let params = {
        id: this.prescription.id,
        type: 3,
        method: 1
      }
      this.$rf.getRequest('DoctorRequest').signDocument(params).then(() => {
        this.getPrescriptionByID(this.prescription.id)
        this.$message({
          message: 'Thành công',
          type: 'success'
        })
      })
    }
  }
}
</script>

<style lang="scss"></style>
