<template>
  <div class="row p-0 fs-14" id="TemplatePrescription">
    <div class="col-12 row mb-3">
      <div class="col-3 d-flex">
        <div v-if="$globalClinic">
          <p class="mb-0 fs-13 font-bold uppercase">
            {{
              $globalClinic && $globalClinic.organization
                ? $globalClinic.organization.authority_in_charge
                : ""
            }}
          </p>
          <p class="mb-0 fs-13 uppercase">
            {{
              $globalClinic && $globalClinic.organization
                ? $globalClinic.organization.name
                : ""
            }}
          </p>
          <p class="mb-0 fs-13">
            {{ $globalClinic.name }}
          </p>
          <p class="mb-0 fs-13" v-if="$globalClinic.address">
            Địa chỉ: {{ $globalClinic.address }}
          </p>
          <p class="mb-0 fs-13" v-if="$globalClinic.phone">
            Số điện thoại: {{ $globalClinic.phone }}
          </p>
        </div>
      </div>
      <div class="col-6">
        <p class="mb-0 text-center text-uppercase font-weight-bold">
          CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
        </p>
        <p class="mb-0 text-center font-weight-bold">
          Độc lập - Tự do - Hạnh phúc
        </p>
        <p class="mb-3 text-center font-weight-bold">
          ------------------------------------
        </p>
      </div>
    </div>
    <div class="col-12">
      <p class="mb-3 text-center text-uppercase font-weight-bold fs-20">
        ĐƠN THUỐC / PRESCRIPTION
      </p>
    </div>
    <div>
      <div v-if="person">
        <div>
          <span class="fw-700">Họ tên bệnh nhân</span>
          <span class="italic">/Patient name: </span>
          <span class="fw-700">{{ person.name }}</span>
        </div>
        <div class="flex">
          <div class="w-1/3">
            <span class="fw-700">Ngày sinh</span>
            <span class="italic">/ DOB: </span>
            <span>{{ getDate() }}</span>
          </div>
          <div class="ml-2">
            <span class="fw-700">Giới tính</span>
            <span class="italic">/ Gender: </span>
            <span>{{ getGender() }}</span>
          </div>
        </div>
        <div>
          <span class="fw-700">Địa chỉ</span>
          <span class="italic">/Address: </span>
          <span>{{ person.address }}</span>
        </div>
        <div class="mt-2" v-if="prescription">
          <div>
            <span class="fw-700">Chỉ định thuốc </span>
            <span class="italic">/ Medication</span>
          </div>
          <div
            class="row"
            v-for="(medicine, index) in prescription.person_medicines"
            :key="index + 'medicine'"
          >
            <div class="col-8">
              <div>
                {{ `${index + 1}. ${medicine.name}` }}
              </div>
              <div class="ml-3.5 italic" v-if="medicine.doctor_note">
                Cách dùng: {{ medicine.doctor_note }}
              </div>
              <div class="ml-3.5 italic" v-if="medicine.side_effect">
                Ghi chú: {{ medicine.side_effect }}
              </div>
            </div>
            <div class="col-4">
              <div>
                Số lượng: {{ formatNumber(medicine.amount) }}
                {{ medicine.amount_unit }}
              </div>
            </div>
          </div>
        </div>

        <div>
          <span class="fw-700">Lời dặn của bác sĩ </span>
          <span class="italic">/ Advice of doctor</span>
          <div v-if="prescription.description">
            - {{ prescription.description }}
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-6 text-center">
            <!-- <div class="flex justify-center">
              <div>Ngày <span>....... </span></div>
              <div>tháng <span>....... </span></div>
              <div>năm <span>....... </span></div>
            </div> -->
            <div class="fw-700">Bác sĩ ký và ghi tên</div>
            <div class="italic">Doctor (Fullname and Signature)</div>
          </div>
          <div class="col-6 text-center">
            <div class="fw-700">Người duyệt đơn</div>
            <div class="italic">Reviewed by</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'TemplatePrescription',
  props: ['person', 'prescription'],
  data () {
    return {
      loading: false
    }
  },
  watch: {
    prescription (s) {
      console.log(s)
    }
  },
  mounted () {
  },
  methods: {
    formatNumber (value) {
      return +value
    },
    getGender () {
      return this.person?.gender && appUtils.getGender(this.person.gender)
    },
    getDate () {
      return this.person?.birthday && window.moment(this.person.birthday).format('DD/MM/YYYY')
    }
  }
}
</script>
